
export default () => {
    if (typeof window.$ != 'undefined')
        return Promise.resolve();
    
    return new Promise((resolve) => {
        var waitForJQuery = setInterval(function () {
            if (typeof window.$ != 'undefined') {
                resolve();
                clearInterval(waitForJQuery);
            }
            console.log("wait for jquery")
        }, 10);
        
    })
}