import waitForJQuery from "./waitForJQuery";
/**
 * We sometimes uses the css :has() selector,
 * that have poor browser support.
 * This script will add a .js-has-<selector> to polyfill
 * this for older navigator.
 */
waitForJQuery().then(function() {
  $(() => {
  $(".button").has("svg").addClass("js--withicon");

  $(".o-results.o-results--show .section")
    .has(".progress-level")
    .addClass("js-with-progress-level");

  $(".m_blogs-posts a.small.hollow")
    .has(".icon--chevron-left")
    .addClass("js-with-back");

  $(".card a")
    .has(".card__image img, .card__image-top img")
    .addClass("js-link-with-image");
  $(".card a")
    .has("img.card__image, img.card__image-top")
    .addClass("js-link-image");

  $(
    ".o-participatory_process_groups.o-participatory_process_groups--show .section .row .column.text-muted"
  )
    .has(".icon--grid-three-up")
    .addClass("js-with-icon-grid-three-up");
})}
);
