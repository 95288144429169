import waitForJQuery from "./waitForJQuery";
waitForJQuery().then(function() {
$(() => {
  $(".card__support").addClass(function () {
    const count = $(this).children().length;
    if (count === 0) return `card__support--empty`;
    return "";
  });
});
});
